@import './../../styles/variables.scss';

.timer {
    margin: 2px;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-evenly;
    font-size: $standard;
    text-transform: uppercase;
    font-weight: $bold;
}
.text {
    margin: auto 2px;
}
.number {
    width: 70px;
    margin: auto 2px;  
}
.firstWarning {
    color: $timerFirstWarning;
}
.secondWarning {
    color: $timerSecondWarning;
}
.finalWarning {
    color: $timerFinalWarning;
    animation: blinker 1s linear infinite;
}
@keyframes blinker {
    50% {
      opacity: 0;
    }
}

