@import './../../styles/variables.scss';

.testOutline {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $grayDark;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
   
    background: white;
    color: black;
    text-align: center;
    width: 400px;
    @media screen and (max-width:$smallWidth) {
        width: 100%;
    }
   
    .title {
        text-transform: uppercase;
        font-size: $subheader;
        display: flex;
        flex-direction: column;
        margin-bottom: 2px;
        border-bottom: 1px solid $grayDark;

        .subTitle {
            text-transform: None;
            font-style: italic;
            font-size: $small;
        }
    }

    .testSectionsContainer {
        display:flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .row {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        padding: 8px 5px;
    }

    .rowActiveStep {
        border: 1px solid $testiveOrange;
    }

    .number {
        font-size: 11px;
        font-weight: $semibold;
        width: 1.5em;
        height: 1.5em;
        box-sizing: initial;
        border: 0.2em solid $grayDark;
        color: $grayDark;
        text-align: center;
        border-radius: 50%;    
        line-height: 1.5em;
        box-sizing: content-box;
        margin-right: 5px;
    }
    .testSection {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: left;
        flex: 70%;

        .sectionTitleSubject {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            font-size: $standard;
            font-weight: $bold;
            text-transform: uppercase;
        }
        .sectionTitleDetails {
            box-sizing: border-box;
            font-size: 14px;
            text-transform: capitalize;
            margin-left: 30px;
            
        }
    }
    .sectionStatus {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        margin: auto 0;
        height: 100%;
        color: black;
        flex: 30%;

        .statusTextCompleted {
            margin: auto 0;
            color: $primaryDark;
            font-weight: $semibold;
            font-size: $small;
            text-transform: uppercase;
        }
        .statusTextActive {
            margin: auto 2px;
            color: $testiveOrange;
            font-weight: $semibold;
            font-size: $standard;
            span {
                font-size: $small;
            }
        }
    }

    img {
        margin: auto 1px;
    }

    .break {
        box-sizing: border-box;
        padding: 8px 5px;
        font-style: italic;
        font-size: $standard;
        background: $primary;
        color: white;
        text-align: center;
    }
    .break.completed {
        background: $grayDark;  
        color: $grayExtraLight; 
    }

    .sectionDetailsNext {
        background: $testiveOrangeLight;
    }

    .sectionBreak {
        margin-top: 5px;
        font-style: italic;
        font-size: $standard;
        color: $testiveOrange;
        text-align: center;
    }
}