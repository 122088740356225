@import './../../styles/variables.scss';

.verifyUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $primaryDark;
   
    .content {
        box-sizing: border-box;
        color: white;
        border: 1px solid $primaryExtraLight;
        border-radius: $borderRadius;
        padding: 35px 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: $header;
        text-transform: uppercase;
        
        font-weight: $bold;
        
        .subHeader {
            font-style: italic;
            text-transform: capitalize;
            font-size: $subheader;
        }
    }
}
