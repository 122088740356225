@import "../../styles/variables";

$buttonColor1: white;
$buttonColor2:$testiveOrange;
$buttonColor3: $testiveOrangeLight;

.button {
  box-sizing: border-box;
  background: $buttonColor1;
  color: $buttonColor2;
  border: 1px solid $buttonColor2;
  border-radius: $borderRadius;
  font-size: $standard;
  font-weight: $semibold;
  outline: none;
  padding: 12px 20px;
  text-transform: uppercase;
  line-height: 1;
  margin: 2px;
  
  &:hover {
    cursor: pointer;
    border-width: 3px;
    margin: 0;
  }

  &:disabled {
    cursor: default;
    background: $grayExtraLight;
    color: $grayLight;
  }
}

.button.cta {
  background: $buttonColor2;
  color: $buttonColor1;
  border-color:  $buttonColor1;
}
