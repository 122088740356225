@import './../../../styles/variables.scss';

.questionContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25px;
    justify-content: flex-start;
    background: white;
}
.questionContainer.shadedRow {
    background: $grayLight;
}
.question {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 127px;
    @media screen and (max-width:$smallWidth) {
        width: 100%;
        justify-content: center;
    }
    .questionNumber {
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 600;
        width: 25px;
        text-align: right;
        margin: auto 0;
        font-family: 'Courier New', Courier, monospace;
        @media screen and (max-width:$smallWidth) {
            text-align: left;
        }
    }
    .answerLetters {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        margin: auto 0;
        font-weight: $semibold;
    }
    .answerLetter {
        outline: none;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 12px;
        border: .5px solid black;
        color: black;
        width: 18px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 0 0 auto 2px;
        cursor: pointer;
        border-radius: 50%;
    }
    .answerLetter:hover {
        background: $highlightColor;
        border-color: $grayDark;
        color: white;
        cursor: pointer;
    }
    .answerLetter.selected {
        background: $grayExtraDark;
    }
    .answerContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .answerLetter {
            border: none;
            font-size: 10px;
            height: initial;
            line-height: initial;
        }
        .answerLetter:hover {
            background: none;
            color: black;
        }
        .answerLetter.selected {
            background: none;
        }
        .answerCircle {
            border: .5px solid black;
            height: 12px;
            width: 12px;
            border-radius: 50%;
        }
        .answerCircle.selected {
            background: $grayExtraDark;
        }
        .answerCircle:hover {
            background: $highlightColor;
            border-color: $grayDark;
            cursor: pointer;
            @media screen and (max-width: $smallWidth) {
                background: $grayExtraDark;
                border-color: black;
            }
        }
    }
}
.disabled {
    .answerLetter:hover {
        background: none;
        border-color: black;
        color: black;
        cursor: not-allowed;
    }
}
