@import './../../styles/variables.scss';

.testSessionBreak {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: lighten($grayExtraDark, 10%);
  // background: $primaryExtraDark;
  height: 100%;
  color:white;
   
  .content {
    box-sizing: border-box;
    // color: black;
    // background: white;
    border: 1px solid $primaryExtraLight;
    border-radius: $borderRadius;
    font-size: $standard;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;

    overflow: auto;
  }
  .title {
    display: flex;
    flex-direction: row;
    font-size: $title;
    font-weight: $bold;
    margin-bottom: 10px;
  } 
  .subTitle {
    display: flex;
    flex-direction: row;
    font-size: $standard;
    margin-bottom: 10px; 
    text-transform: uppercase;
    align-items: center;
  }
  .info {
    font-size: $standard;
    margin: 0 60px 30px;
  } 
  .buttonContainer {
    margin-top: 10px;
  }
}