@import './../../styles/variables.scss';

$marginVertical: 2vh;

.resetPassword {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: white;
    align-items: center;
    justify-content: center;
    min-height: 100%;  
    padding: 1vh 1vw; 
    background-color: $primaryDark;

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
        color: white;
        padding: 20px 30px;
        border: 1px solid white;
        border-radius: $borderRadius;
        background-color: $primaryDark;
    }
    .title {
        font-size: $header;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: $marginVertical;
    }
    .subheader {
        font-size: $subheader;
        text-align: center;
        margin-bottom: $marginVertical;
    }
    .buttonContainer {
        display: flex;
        flex-direction: row;
        margin: 1vh 0;
    }
    input {
        padding: 5px;
        box-sizing: border-box;
        font-size: $standard;
        min-height: 40px;
        border: 1px solid $gray;
        background-color: white;
        margin-bottom: 1vh;
        border-color: $grayDark;
        border-radius: $borderRadius;
        width: 100%;
        outline:none;
    }
    input[type=submit] {
        border: none;
        font-size: $standard;
        border: 1px solid white;
        background-color: $testiveOrange;
        color: white;
        -webkit-border-radius: $borderRadius;
        border-radius: $borderRadius;
        box-sizing:content-box;
        font-size: $standard;
        font-weight: $semibold;
        outline: none;
        padding: 0 10px;
        text-transform: uppercase;
        margin: 2px;
    }
    input[type=submit]:hover  {
        cursor: pointer;
        border-width: 3px;
        margin: 0;
    }

}