@import './../../styles/variables.scss';

.testInstructions {
    box-sizing: border-box;
    background: $backgroundColor;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: $standard;
    color: black;
    overflow: auto;
    .topContainer {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 25px auto 10px;
        max-width: 1000px;
        overflow-y: auto;
        @media screen and (max-width:$breakWidth) {
            min-height: min-content;
            padding: 0 15px;
        }
    }
    .topContainerSplit {
        box-sizing: border-box;
        display: flex;
        flex-direction: row; 
        align-items: center;
        @media screen and (max-width:$breakWidth) {
            flex-direction: column;
            box-sizing: content-box;
            .right, .left {
                width: 100%;
                padding: 0;
                margin: 0;
            }
        }
        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin: 0 10px;
        }
        .right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px 0;
        }
    }
    .title {
        text-align: center;
        width: 100%;
        font-weight: $bold;
        font-size: $header;
    }
    .buttonContainer {
        margin-top: 3px;
    }
    .checkContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    .checkbox {
        width: auto;
        margin-top: 0;
    }
    .testOutline {
        @media screen and (max-width:$smallWidth) {
            width: 100%;
        }
    }
    .ctaCallOut {
        color: $testiveOrange;
        font-weight: $bold;
        font-size: $subheader;
        text-transform: uppercase;
    }
    .warning {
        color: $testiveOrange;
        font-weight: bold;
    }
    .callOut {
        font-weight: $bold;
    }

    .footer {
        display: flex;
        margin-top: auto;
        width: 100%;
    }
}

.smallScreenWarning {
    color: $errorColor;
    font-weight: bold;
    font-size: 18px;
    display: none;
    @media screen and (max-width: $breakWidth) {
        display: block;
    }
}