@import './../../styles/variables.scss';

.login {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: white;
    align-items: center;
    justify-content: center;
    min-height: 100%; 
    padding: 1vh 1vw;
    background-color: $primaryDark;

    .mainContainer {
        box-sizing: border-box;
        padding: 4vh 4vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: $borderRadius;
        height: 100%;
    }
    .logoContainer {
        justify-self: flex-start;
        margin: 2vh auto;
    }
    .title {
        font-size: $header;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 3vh;
    }
    .subtitle {
        margin-bottom: 1vh;
    }
    .formContainer {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        min-width: 200px;
        padding: 0 2vw;
        margin-bottom: 2vh;
    }
    .buttonContainer {
        display: flex;
        flex-direction: row;
        margin: 1vh 0;
    }
    input, select {
        padding: 5px;
        box-sizing: border-box;
        font-size: $standard;
        min-height: 40px;
        border: 1px solid $gray;
        background-color: white;
        color: black;
        margin-bottom: 1vh;
        border-color: $grayDark;
        border-radius: $borderRadius;
        width: 100%;
        outline:none;
    }
    select:invalid {
        color: gray;
    }
    input[type=submit] {
        border: none;
        font-size: $standard;
        border: 1px solid white;
        background-color: $testiveOrange;
        color: white;
        -webkit-border-radius: $borderRadius;
        border-radius: $borderRadius;
        box-sizing:content-box;
        font-size: $standard;
        font-weight: $semibold;
        width: initial;
        outline: none;
        padding: 0 10px;
        text-transform: uppercase;
        margin: 2px;
    }
    input[type=submit]:hover  {
        cursor: pointer;
        border-width: 3px;
        margin: 0;
    }
}