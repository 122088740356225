@import './../../styles/variables.scss';

.pdfViewer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // width: $pdfWidth;
}
.pdfDocument {
  overflow-y: auto;
  overflow-x: auto;
  box-sizing: border-box;
  height: 100%;
}
.navLinks {
  border-top: 1px solid $primary;
  display: flex;
  flex-direction: row; 
  font-size: $small;
  text-transform: uppercase;
  justify-content: space-evenly;
  width: 100%;
  height: 30px;
  @media screen and (max-width:$smallWidth) {
    justify-content: space-between;
    width: 96%;
    margin: auto;
  }
  .buttonImage {
    cursor: pointer;
    margin: auto;
  }
  
}
.disabled
{
  cursor: default;
  opacity:0.25;
}
.zoom {
  display: flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0 2px;
  span {
    margin: auto 2px;
  }
}
.paging {
  display:flex;
  flex-direction: row;
  margin: auto 0;
  padding: 0 2px;
  span {
    margin: auto 2px;
  }
} 

