@import './../../styles/variables.scss';

.testInstructions {
    background: $backgroundColor;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: $standard;
    overflow: auto;
}
.topContainer {
    color: black;
    display: flex;
    flex-direction: column;
    height: 100%; 
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    max-width: 800px;
    @media screen and (max-width:$breakWidth) {
        min-height: min-content;
        padding: 0 15px;
    }
}
.warning {
    margin-bottom: 5px;
}
.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.info {
    padding-top: 20px;
    font-size: 18px;
}
.checkContainer {
    display: flex;
    align-items: center;
}
.checkbox {
    width: auto;
    margin-top: 0;
}
// .startButton {
//     color: #ffffff;
//     background: $testiveOrange;
//     border-radius: $borderRadius;
//     font-size: 24px;
//     padding: 10px 15px;
//     margin-top: 3vh;
//     &:hover {
//         cursor: pointer;
//         background: $testiveOrangeLight;
//         border: 1px solid white;
//     }
// }
.ctaCallOut {
    color: $testiveOrange;
    font-weight: $bold;
    font-size: $subheader;
    text-transform: uppercase;
}
.callOut {
    font-weight: $bold;
}
.footer {
    display: flex;
    margin-top: auto;
    width: 100%;
}