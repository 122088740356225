@import './../../styles/variables.scss';

.testSectionAnswers {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    // font-family: 'Courier New', Courier, monospace;
    // border: 1px solid green;
}
.title {
    font-size: $standard;
    font-weight: $bold;
    text-transform: uppercase;
    color: $grayExtraDark;
    background:white;
    width: 100%;
    padding: 5px 0;
    text-align: center;
    border-bottom: 1px solid $primary;
}
.columns {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow: auto;
    @media screen and (max-width:$smallWidth) {
        flex-direction: column;
    }
}
.questionColumn {
    box-sizing: border-box;
    width: 135px;
    @media screen and (max-width:$smallWidth) {
        width: 100%;
    }
}
.imageLeft {
    cursor: pointer;
    margin-right: 15px;
    margin-left: auto;
}
.imageRight {
    cursor: pointer;
    margin-left: 15px;
    margin-right: auto;
}
.disabled {
  cursor: default;
  opacity:.25;
}