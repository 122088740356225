@import './../../styles/variables.scss';
// @import '../../assets/Images'; 

.testCompleted {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lighten($primaryExtraDark, 10%);
  height: 100%;
  color:white;
  font-size: $standard;

  // .backgroundImage {
  //   width: 100%;
  //   background-image: url('../../assets/images/congratulations.png');
  //   height: 300px; /* You must set a specified height */
  //   background-position: center; /* Center the image */
  //   background-repeat: no-repeat; /* Do not repeat the image */
  //   background-size: cover;   
  // }

  .waitingContainer {
    display: flex;
    margin-top: 10;
    
  }
  .content {
    box-sizing: border-box;
    border-radius: $borderRadius;
    font-size: $standard;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 600px;

    overflow: auto;
  }
  .title {
    display: flex;
    flex-direction: row;
    font-size: 4rem;
    font-weight: $bold;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-style: italic;
    @media screen and (max-width:$smallWidth) {
      font-size: 3rem;
    }
  } 
  .subTitle {
    font-size: $header;
    margin-bottom: 80px;
    @media screen and (max-width:$smallWidth) {
    }
  }
  .testiveIntro {
    margin-bottom: 50px;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

  }

  
  .wait {
    animation: wait-spin infinite 20s linear;
    height: 40vmin;
  }
  @keyframes wait-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

}
