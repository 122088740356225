@import './../../styles/variables.scss';

.testSectionDirections {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $grayExtraDark;
    opacity: .9;

    .content {
        box-sizing: border-box;
        color: white;
        border: 1px solid $primaryExtraLight;
        border-radius: $borderRadius;
        font-size: $standard;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        margin: 40px;
        overflow: auto;
        span {
            font-weight: $bold;
        }
    }
   
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: $standard;
        text-transform: uppercase;
        .subject {
            font-weight: $bold;
            font-size: $subheader;
        }
        .details {
            font-style: italic;
            text-transform: capitalize;
        }
    }

    .timerWarning {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: $semibold;
        color: $timerFinalWarning;
        align-items: center;
        font-size: $standard;
        text-transform: uppercase;
        margin-bottom: 5px;
      
    }
    .timer {
        border: 2px dotted $timerFinalWarning;
        border-radius: $borderRadius;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 20px;
        padding: 5px 10px;
    }
}