@import './../../styles/variables.scss';

.container {
    height: 100%;
}

.testSession {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    @media screen and (max-width:$smallWidth) {
        min-width: 0;
    }
}
.testSectionHeader {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: $headerHeight;
    width: 100%;
    height: 4vh;
    background: $headerColor;
    padding: 0 8px;
    border-bottom: 1px solid $primaryLight;
    @media screen and (max-width:$smallWidth) {
        padding: 0;
        height: 4vh;
    }
    .testSectionTitle {
        color: white;
        margin: auto 2px;
        padding: 2px; 
        display: flex;
    }
    .testSectionTitleSubject {
        font-size: $standard;
        font-weight: $bold;
        text-transform: uppercase;
        padding: 2px;
        color: white;
        @media screen and (max-width:$smallWidth) {
            display: none;
        }
    }
    .testSectionTitleDetails {
        font-size: $standard;
        font-style: italic;
        text-transform: capitalize;
        margin: auto 2px;
        @media screen and (max-width:$smallWidth) {
            display: none;
        }
    }
    .headerButtons {
        display: flex;
        flex-direction: row;
        @media screen and (max-width:$smallWidth) {
            justify-content: space-between;
        }
    }
    .sessionButton {
        box-sizing: border-box;
        background: $headerColor;
        color: $highlightColor;
        font-size: $small;
        border: 1px solid $highlightColor;
        margin: 2px;
        border-radius: $borderRadius;
        &:hover {
            cursor: pointer;
            color: $headerColor;
            background: $highlightColor;
            border-color: $headerColor;
        }
    }

}
.buttonImage {
    cursor: pointer;
    margin: auto;
}
.spitScreen {
    display: flex;
    flex-direction: row;
    height: 92vh;
    @media screen and (max-width:$smallWidth) {
        flex-direction:column;
        max-height: 92vh;
        overflow-y: hidden;
    }
}
.testBooklet {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // width: $pdfWidth;
    width: 100%;
    min-width: 300px;
    @media screen and (max-width:$smallWidth) {
        max-height: 94%;
    }
}
.answerSheet {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 275px;
    min-width: 275px;
    border-left: 3px double $headerColor;
    @media screen and (max-width:$smallWidth) {
        width: 140px;
        min-width: 140px;
    }
}
.answerSheetMobile {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: auto;
    height: 85vh;
}
.instructions {
    font-size: 16px;
}
.testBreak {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}