@import './../../../styles/variables.scss';

.questionContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25px;
    justify-content: flex-start;
    background: white;
}
.questionContainer.shadedRow {
    background: $grayLight;
}
.question {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 127px;
    .questionNumber {
        // border: 1px solid yellow;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
        width: 25px;
        text-align: right;
        margin: auto 0;
        @media screen and (max-width:$smallWidth) {
            text-align: left;
        }
    }
    .answerInput {
        display: flex;
        margin: auto;
        input {
            width: 80%;
            margin-left: 6px;
        }
    }
}
