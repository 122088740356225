@import './../../../styles/variables.scss';

.link {
    border: none;
    color: $primaryExtraLight;
    font-size: $standard;
}
.link:hover {
    cursor: pointer;
    color: $primaryLight;
}