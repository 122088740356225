@import '../../../styles/variables.scss';

.confirmationModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $grayExtraDark;
  opacity: .9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color:white;
  z-index: 100;
  .content {
    border: 1px solid white;
    border-radius: $borderRadius;
    background-color: rgba($color: #fff, $alpha: .1);
    font-size: $standard;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    overflow: auto;
  }
  .text {
    margin: 10px 0 20px 0;
    font-size: $subheader;
  }
  .title {
    display: flex;
    flex-direction: row;
    font-size: $titleExtraLarge;
    font-weight: $bold;
    margin: 20px 0;
    text-transform: uppercase;
    color: $testiveOrange;
  } 
  
  .buttonContainer {
    margin-top: 10px;
  }
}