@import './../../../styles/variables.scss';

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4vh;
    background: $primaryExtraDark;
    width: 100%;
    padding: 0 8px;
    border-top: 1px solid $primaryLight;
}
.left, .right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.left {
    div {
        margin-right:12px;
    }
}
.text {
    color: white;
    @media screen and (max-width: $smallWidth) {
        display: none;
    }
}
.logo {
    height: 26px;
    margin-right: 20px;
}