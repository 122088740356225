@import './../../styles/variables.scss';

$marginVertical: 2vh;

.confirmUser {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: white;
    align-items: center;
    justify-content: center;
    min-height: 100%; 
    padding: 1vh 1vw;
    background-color: $primaryDark;

    .mainContainer {
        box-sizing: border-box;
        padding: 4vh 4vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: $borderRadius;
        height: 100%;
    }
    .logoContainer {
        justify-self: flex-start;
        margin: 2vh auto;
    }
    .title {
        font-size: $header;
        text-transform: capitalize;
        text-align: center;
    }
    .displayName {
        font-size: $header;
        font-weight: $semibold;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: $marginVertical;
    }
    .subTitle {
        font-size: $subheader;
        text-align: center;
    }
    .buttonContainer {
        display: flex;
        flex-direction: column;
        margin: 1vh 0;
    }
}