// Colors
$testiveOrange: #f58b1e;
$testiveOrangeLight: #fac58e;
$primary: #00a2a7;
$primaryLight: #00d3da;
$primaryExtraLight: #01F0FB;
$primaryDark: #007C82;
$primaryExtraDark: #006165;
$secondary: #134f8d;
$secondaryLight: #5ba0d0;
$secondaryDark: #2c3e50;
$secondaryExtraLight: #ceddef;
$gray: #cecece;
$grayDark: #959ea7;
$grayExtraDark: #1d1f21;
$grayLight: #e9ebed;
$grayExtraLight: #f6f6f6;
$errorColor: #ff0033;
$errorColor2: #8e3122;
$highlightColor:#01F0FB;
$backgroundColor: #e9ebed;
$color: black;
$headerColor: #007C82;

$timerDefault: #00d3da;
$timerFirstWarning: #F9F871;
$timerSecondWarning: #f58b1e;
$timerFinalWarning: #ff0033; 
// #F3615E

// Fonts
$primaryFont: 'proxima-nova';
$headerFont: 'Roboto Slab';
$fontColor: #2c3e50;

$headerHeight: 36px;

// Font Sizes
$extraSmall: 0.25rem;
$small: 12px;
$standard: 16px;
$subheader: 18px;
$header: 1.5rem;
$title: 2rem;
$titleLarge: 32px;
$titleExtraLarge: 42px;

// Font Weights
$thin: 100;
$light: 300;
$regular: 400;
$regularItalic: 400;
$semibold: 600;
$bold: 700;
$extraBold: 800;

// miscellaneous
$borderRadius: 5px;
$maxWidth: 800px;

// breakpoints
$viewPhone: 600px;
$viewTablet: 900px;
$viewDesktop: 1200px;

// 595 * 842
$pdfWidth: 900px; 
$pdfPageWidth: 900;

$breakWidth: 1024px;
$smallWidth: 775px;