@import './../../styles/variables.scss';

.chooseTest {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: white;
  justify-content: center;
  min-height: 100%; 
  background-color: $primaryDark;

  .topContainer {
    box-sizing: border-box;
    padding: 10vh 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .logoContainer {
    justify-self: flex-start;
    margin: 2vh auto;
  }
  .title {
      text-align: center;
      width: 100%;
      // font-weight: $bold;
      font-size: $titleLarge;
  }
  .subtitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
    font-size: $subheader;
    text-align: center;
    width: 60%;
  }
  .testHeader {
    margin-bottom: 3vh;
    font-size: $subheader;
    text-transform: uppercase;
  }
  .choiceBox {
    margin-top: 3vh;
    font-size: $header;
    padding: 5vh 2vw 3vh;
    display: flex;
    border-radius: $borderRadius;
    border: 1px solid white;
    width: 350px;
    flex-direction: column;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    // padding: 55px 35px 15px 35px;
    button {
      font-size: 1.3rem;
    }
  }
  .testContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: $header;
    justify-content: space-between;
    padding: 15px 0;
  }
  .buttonContainerSmall {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    button {
      font-size: $subheader;
    }
  }
  .footer {
      display: flex;
      margin-top: auto;
      width: 100%;
  }
}